import { FC, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
    svgClass?: string | undefined;
}

const Developer: FC<Props> = ({ svgClass }) => (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Sign_up-Transcription" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1-0-1-sign_up-transcription" transform="translate(-908.000000, -251.000000)">
                <g id="right" transform="translate(480.000000, 0.000000)">
                    <g id="group" transform="translate(71.000000, 98.000000)">
                        <g id="fields" transform="translate(0.000000, 129.000000)">
                            <g id="2" transform="translate(256.000000, 0.000000)">
                                <g id="developer_user-icon" transform="translate(101.000000, 24.000000)">
                                    <polygon id="Path" points="0 0 40 0 40 40 0 40"></polygon>
                                    <polyline id="Path" stroke="#FEB33E" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" points="11.6666667 13.3333333 5 20 11.6666667 26.6666667"></polyline>
                                    <polyline id="Path" stroke="#FEB33E" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" points="28.3333333 13.3333333 35 20 28.3333333 26.6666667"></polyline>
                                    <line x1="23.3333333" y1="6.66666667" x2="16.6666667" y2="33.3333333" id="Path" stroke="#FEB33E" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></line>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Developer