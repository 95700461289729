/** @format */

import React, { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import styles from "./AuthTopRow.module.scss";
import { useTranslation } from "react-i18next";

export interface IAuthTopRowProps extends HTMLAttributes<HTMLElement> {
	fromAppSelection?: boolean;
}

const AuthTopRow = ({ fromAppSelection }: IAuthTopRowProps) => {
	const { t } = useTranslation();

	function textSwitch() {
		if (fromAppSelection) {
			return t("Not a member?");
		} else {
			return t("Already a member?");
		}
	}

	return (
		<div className={styles.authTopRow}>
			<span>{textSwitch()}</span>
			<Link to={`${fromAppSelection ? `/signup` : `/`}`}>
				{fromAppSelection ? t("Sign Up") : t("Log In")}
			</Link>
		</div>
	);
};

export default AuthTopRow;
