import { DOMAIN_URL } from "../config";

export const AppLinks = {
  transcribe: `https://transcribe.${DOMAIN_URL}`, //Transcribe app
  speechAnalytics: `https://analytics.${DOMAIN_URL}`, //Speech analytics (webapp)
  console: `https://console.${DOMAIN_URL}`, //ACP portal
};

export const SignupLinks = {
  transcribe: `https://transcribe.${DOMAIN_URL}/signup`, //Transcribe app
  speechAnalytics: `https://analytics.${DOMAIN_URL}/register`, //Speech analytics (webapp)
  console: `https://console.${DOMAIN_URL}/signup`, //ACP portal
};

export const LegalTextsLinks = {
  privacyPolicy:`https://raw.githubusercontent.com/voicegain/platform/master/PRIVACY.md`, //Privacy Policy (opens Privacy Policy stored in Voicegain's GitHub)
  termsAndConditions: `https://raw.githubusercontent.com/voicegain/platform/master/TERMS-OF-SERVICE.md` //Terms and conditions (opens Terms and Conditions stored in Voicegain's Github)
}
