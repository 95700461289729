/** @format */

import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import AuthSidebar from "../../components/Sidebar/AuthSidebar";
import AuthTopRow from "../../components/Sidebar/AuthTopRow";
import CallCenterIcon from "../../components/svg/CallCenter";
import DeveloperIcon from "../../components/svg/Developer";
import TranscriptionIcon from "../../components/svg/Transcription";
import { AppLinks } from "../../constants/AppLinks";
import styles from "./AppSelector.module.scss";
import matomoTracker from "../../matomo";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

export enum AppSelectionValues {
	Transcription = "transcribe",
	Developer = "console",
	CallCenter = "speechAnalytics",
}

const AppSelector = () => {
	const { t } = useTranslation();
	const { Transcription, Developer, CallCenter } = AppSelectionValues;
	const [selectedApp, setSelectedApp] = useState<
		AppSelectionValues | undefined
	>(undefined);

	const trackButtonClick = (
		category: string,
		action: string,
		event: string
	) => {
		matomoTracker.track({
			url: window.location.href,
			e_c: category,
			e_a: action,
			e_n: event,
		});
	};

	useEffect(() => {
		matomoTracker.track({
			url: window.location.href,
		});
	}, []);

	return (
		<div className={styles.appSelectorWrap}>
			<AuthSidebar />
			<div className={styles.appSelectorContent}>
				<AuthTopRow fromAppSelection={true} />
				<div className={styles.appsContainer}>
					<div className={styles.apps}>
						<div className={styles.appSelectorTitle}>
							<h2>{t("Which app do you want to log in to?")}</h2>
							<div className={styles.appDescription}>
								{t(
									"Select from one of the following applications based on your use-case"
								)}
							</div>
						</div>
						<div className={styles.appSelectorColWrap}>
							<div className={styles.appSelectorColOuter}>
								<button
									className={styles.appSelectorCol}
									type="button"
									onClick={() => {
										trackButtonClick("Login", "continue", "transcribe");
										window.location.href = AppLinks[Transcription];
									}}>
									<div className={styles.appSelectorColHead}>
										<div className={styles.appSelectorUserIcnContainer}>
											<i className={styles.appSelectorUserIcn}>
												<TranscriptionIcon />
											</i>
											<h6 className={styles.logInHeading}>{t("Transcribe")}</h6>
										</div>

										<p className={styles.logInPoint}>
											{t(
												"Record and transcribe meetings, conversations, talks, and more."
											)}
										</p>

										<div className={styles.appSelectorBtnArea}>
											<Button
												className={styles.loginBtn}
												type="button"
												buttonColor="orange">
												{t("Continue")} →
											</Button>
										</div>
									</div>
								</button>
							</div>
							<div className={styles.appSelectorColOuter}>
								<button
									className={styles.appSelectorCol}
									type="button"
									onClick={() => {
										trackButtonClick("Login", "continue", "developer");
										window.location.href = AppLinks[Developer];
									}}>
									<div className={styles.appSelectorColHead}>
										<div className={styles.appSelectorUserIcnContainer}>
											<i className={styles.appSelectorUserIcn}>
												<DeveloperIcon />
											</i>
											<h6 className={styles.logInHeading}>
												{t("Developer Console")}
											</h6>
										</div>

										<p className={styles.logInPoint}>
											{t(
												"Build your app using our comprehensive set of APIs, SDKs, sample scripts, and more."
											)}
										</p>

										<div className={styles.appSelectorBtnArea}>
											<Button
												className={styles.loginBtn}
												type="button"
												buttonColor="orange">
												{t("Continue as Developer")} →
											</Button>
										</div>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<LanguageSelector />
		</div>
	);
};

export default AppSelector;
