/** @format */

import React, { useState, useEffect } from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { MemoryRouter as Router, Routes, Route } from "react-router-dom";
import AppSelector from "./pages/AppSelector/AppSelector";
import SignupSelector from "./pages/Signup/SignupSelector";
import { createContext } from "react";

export const AppContext = createContext<{
	setSnackbarOpen: (open: boolean) => void;
	setSnackbarMessage: (message: string | undefined) => void;
}>({
	setSnackbarOpen: () => {},
	setSnackbarMessage: () => {},
});

const App = () => {
	const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
	const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(
		undefined
	);

	return (
		<AppContext.Provider value={{ setSnackbarOpen, setSnackbarMessage }}>
			<Router>
				<Routes>
					<Route path="/" element={<AppSelector />} />

					<Route path="/signup" element={<SignupSelector />} />
				</Routes>
			</Router>
		</AppContext.Provider>
	);
};

export default App;
