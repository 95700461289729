import { FC, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
    svgClass?: string | undefined;
}

const IconClose: FC<Props> = ({ svgClass }) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="14px" height="14px" viewBox="-1 -1 14 14" enableBackground="new -1 -1 14 14">
        <path fill="#333333" d="M7.282,6.001l5.449-5.45c0.356-0.355,0.356-0.928,0-1.283c-0.354-0.355-0.928-0.355-1.282,0l-5.45,5.45
	L0.55-0.734c-0.355-0.354-0.928-0.354-1.283,0c-0.354,0.355-0.354,0.927,0,1.284L4.717,6l-5.45,5.451
	c-0.354,0.355-0.354,0.927,0,1.282C-0.557,12.91-0.323,13-0.093,13c0.232,0,0.465-0.089,0.643-0.267l5.449-5.449l5.45,5.449
	C11.626,12.91,11.858,13,12.091,13c0.231,0,0.464-0.089,0.641-0.267c0.356-0.355,0.356-0.927,0-1.282L7.282,6.001z"/>
    </svg>
)

export default IconClose