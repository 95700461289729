import React from "react";
import styles from "./Modal.module.scss";
import LoadingOverlay from "../Loading/LoadingOverlay";
import IconClose from "../svg/IconClose";

interface IModalBodyProps {
  title?: string;
  toggleOpen: (isOpen: boolean) => void;
  spinning?: boolean;
  children?: React.ReactNode;
}

const ModalBody = ({
  title,
  toggleOpen,
  spinning,
  children,
}: IModalBodyProps) => {
  return (
    <LoadingOverlay spinning={spinning}>
      <div className={styles.modalBodyContainer}>
        <div className={styles.header}>
          <h2>{title}</h2>
          <div onClick={() => toggleOpen(false)}>
            <IconClose />
          </div>
        </div>
        <div className={styles.childrenContent}>{children}</div>
      </div>
    </LoadingOverlay>
  );
};

export default ModalBody;
