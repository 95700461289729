/** @format */

import { useState } from "react";
import styles from "./LanguageSelector.module.scss";
import Globe from "../svg/Globe";
import { useTranslation } from "react-i18next";

function LanguageSelector() {
	const { i18n } = useTranslation();
	const { t } = useTranslation();
	const [clicked, setClicked] = useState(false);
	const [changeLangHover, setChangeLangHover] = useState(false);

	return (
		<>
			{clicked ? (
				<div
					className={styles.languageList}
					style={{
						right: "0px",
					}}>
					<button
						className={styles.specificLanguage}
						onClick={() => {
							i18n.changeLanguage("en");
							setClicked(false);
							setChangeLangHover(false);
						}}>
						{t("English")}
					</button>
					<button
						onClick={() => {
							i18n.changeLanguage("es");
							setClicked(false);
							setChangeLangHover(false);
						}}
						className={styles.specificLanguage}>
						{t("Spanish-Español")}
					</button>
					<button
						onClick={() => {
							i18n.changeLanguage("hi");
							setClicked(false);
							setChangeLangHover(false);
						}}
						className={styles.specificLanguage}>
						{t("Hindi-हिंदी")}
					</button>
					<button
						onClick={() => {
							i18n.changeLanguage("de");
							setClicked(false);
							setChangeLangHover(false);
						}}
						className={styles.specificLanguage}>
						{t("German-Deutsch")}
					</button>
				</div>
			) : (
				<button
					onMouseEnter={() => setChangeLangHover(true)}
					onMouseLeave={() => setChangeLangHover(false)}
					className={
						changeLangHover
							? styles.languageSelectorWrapperHovered
							: styles.languageSelectorWrapper
					}
					onClick={() => setClicked(true)}>
					<div
						style={{
							transform: changeLangHover ? "rotate(0deg)" : "rotate(45deg)",
							marginLeft: "-5px",
							marginBottom: "-5px",
						}}>
						<Globe />
					</div>

					{changeLangHover ? (
						<div className={styles.ChangeLanguageText}>
							{t("Change Language?")}
						</div>
					) : null}
				</button>
			)}
		</>
	);
}

export default LanguageSelector;
