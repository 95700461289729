import React, { HTMLAttributes } from "react";
import styles from "./Select.module.scss";
import SvgArrow from "./styled/SvgArrow";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";

export interface ISelectOption {
  value: string;
  label: string;
}

export interface CustomSelectProps<T = string>
  extends HTMLAttributes<HTMLElement> {
  options: readonly { value: T; label: string }[];
  onSelectOption?: (s: any) => void;
  valueSelected?: T;
  defaultSelectedValue?: any;
  placeholder?: string;
  isClearable?: boolean;
  isCreatable?: boolean;
  isMultiSelect?: boolean;
  value?: string;
  hasNoBorder?: boolean;
  isDisabled?: boolean;
  label?: string;
  maxMenuHeight?: number;
}

const CustomSelect = ({
  options,
  onSelectOption,
  valueSelected,
  defaultSelectedValue,
  placeholder,
  isClearable,
  isCreatable,
  isMultiSelect,
  value,
  hasNoBorder,
  isDisabled,
  label,
  maxMenuHeight,
  ...rest
}: CustomSelectProps) => {
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <SvgArrow
          viewBox="0 0 80 60"
          width="8"
          height="6"
          isOpen={props.selectProps.menuIsOpen}
        >
          <path d="M0 0 L60 0 L30 60 Z" fill="#333333" />
        </SvgArrow>
      </components.DropdownIndicator>
    );
  };
  const colourStyles = {
    option: (styles: any, { isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: "transparent",
        color: "#333333",
        ":hover": {
          backgroundColor: "lightgray",
        },
        ":active": {
          backgroundColor: "lightgray",
        },
      };
    },
    indicatorSeparator: () => ({ display: "none" }),
    valueContainer: () => ({
      height: 40,
      paddingLeft: 8
    }),
    menu: (style: any, { isFocused }: any) =>
      hasNoBorder
        ? {
            ...style,
            margin: 0,
            padding: 0,
          }
        : {
            ...style,
            margin: 0,
            padding: 0,
            borderRadius: "0 0 4px 4px",
            border: "1px solid #333333",
            borderTop: "none",
            boxShadow: "none",
          },
    control: (style: any, { isFocused, menuIsOpen }: any) =>
      hasNoBorder
        ? {
            ...style,
            boxShadow: "none",
            border: "none",
            height: 40,
          }
        : {
            ...style,
            height: 40,
            boxShadow: "none",
            borderBottom: menuIsOpen ? "none" : "",
            borderRadius: menuIsOpen ? "4px 4px 0 0" : "4px",
            outlineColor: "#333333",
            borderColor: menuIsOpen ? "#333333" : "#E3E3E3",
            ":hover": {
              borderColor: "black",
            },
          },
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };

  if (isCreatable) {
    return (
      <div className={styles.selectContainer}>
        {label ? <label>{label}</label> : ""}
        <CreatableSelect
          options={options}
          isClearable={isClearable}
          styles={colourStyles}
          components={{ DropdownIndicator }}
          onChange={onSelectOption}
          value={options.find((option) => option.value === value)}
          placeholder={placeholder}
          defaultValue={options.find(
            (option) => option.value === defaultSelectedValue
          )}
          isDisabled={isDisabled}
          maxMenuHeight={maxMenuHeight}
        />
      </div>
    );
  } else if (isMultiSelect) {
    return (
      <div className={styles.selectContainer}>
        {label ? <label>{label}</label> : ""}
        <CreatableSelect
          options={options}
          isClearable={isClearable}
          isMulti={true}
          styles={colourStyles}
          components={{ DropdownIndicator }}
          onChange={onSelectOption}
          value={options.find((option) => option.value === value)}
          placeholder={placeholder}
          defaultValue={options.map((option) => {
            if (defaultSelectedValue.includes(option.value)) return option;
            else return undefined;
          })}
          isDisabled={isDisabled}
          maxMenuHeight={maxMenuHeight}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.selectContainer}>
        {label ? <label>{label}</label> : ""}
        <Select
          options={options}
          isClearable={isClearable}
          styles={colourStyles}
          value={options.find((option) => option.value === value)}
          components={{ DropdownIndicator }}
          onChange={onSelectOption}
          isSearchable={false}
          placeholder={placeholder}
          defaultValue={options.find(
            (option) => option.value === defaultSelectedValue
          )}
          isDisabled={isDisabled}
          maxMenuHeight={maxMenuHeight}
          menuPortalTarget={document.body}
        />
      </div>
    );
  }
};

export default CustomSelect;
