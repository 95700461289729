import { FC, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLElement> {
    svgClass?: string | undefined;
}

const Transcription: FC<Props> = ({ svgClass }) => (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Sign_up-Transcription" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1-0-1-sign_up-transcription" transform="translate(-651.000000, -251.000000)">
                <g id="right" transform="translate(480.000000, 0.000000)">
                    <g id="group" transform="translate(71.000000, 98.000000)">
                        <g id="fields" transform="translate(0.000000, 129.000000)">
                            <g id="transcription_user-icon" transform="translate(100.000000, 24.000000)">
                                <polygon id="Path" points="0 0 40 0 40 40 0 40"></polygon>
                                <path d="M23.3333333,5 L23.3333333,11.6666667 C23.3333333,12.5871412 24.0795254,13.3333333 25,13.3333333 L31.6666667,13.3333333" id="Path" stroke="#FEB33E" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M8.33333333,18.3333333 L8.33333333,8.33333333 C8.33333333,6.49238417 9.8257175,5 11.6666667,5 L23.3333333,5 L31.6666667,13.3333333 L31.6666667,31.6666667 C31.6666667,33.5076158 30.1742825,35 28.3333333,35 L12.5,35" id="Path" stroke="#FEB33E" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M16,27.5 L14.6682943,27.5 C14.0730219,27.5 13.5616469,26.9108125 13.4957274,26.125 C13.298635,23.753125 12.9970037,22 11.665298,22 C10.3335923,22 10.0006659,24.462625 10.0006659,27.5 C10.0006659,30.537375 9.66773943,33 8.33603374,33 C7.00432804,33 6.70336256,31.2489375 6.50560426,28.875 C6.43901898,28.0864375 5.92830984,27.5 5.33170569,27.5 L4,27.5" id="Path" stroke="#FEB33E" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)

export default Transcription