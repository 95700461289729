import React, { InputHTMLAttributes } from "react";
import styles from "./TextArea.module.scss";

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  cols?: number;
  rows?: number;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ cols, rows, ...rest }, ref) => {
    return (
      <textarea
        ref={ref}
        className={styles.textArea}
        cols={cols}
        rows={rows}
        {...rest}
      ></textarea>
    );
  }
);

export default TextArea;
