import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITEKEY } from "../../config";
import { IReCaptchaProps } from "./ReCaptcha.types";

const ReCaptcha: React.FC<IReCaptchaProps> = ({
  onExpire,
  onSuccess,
  reCaptchaRef,
}) => {
  return (
    <ReCAPTCHA
      onChange={(token) => {
        if (token === null) {
          if (onExpire) {
            onExpire();
          }
        } else {
          onSuccess(token);
        }
      }}
      sitekey={RECAPTCHA_SITEKEY}
      ref={reCaptchaRef}
    />
  );
};

export default ReCaptcha;
