import { FC, HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLElement> {
  svgClass?: string | undefined;
}

const Enterprise: FC<Props> = ({ svgClass }) => (
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>3D19A29E-E102-4586-8F80-4477F833ABF8</title>
    <g
      id="Enterprise"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="1-0-1-enterprise" transform="translate(-679.000000, -186.000000)">
        <g id="card" transform="translate(420.000000, 98.000000)">
          <g id="group" transform="translate(89.500000, 88.000000)">
            <g id="enterprise-icon" transform="translate(169.500000, 0.000000)">
              <polygon id="Path" points="0 0 80 0 80 80 0 80"></polygon>
              <line
                x1="10"
                y1="70"
                x2="70"
                y2="70"
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
              <polyline
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="16.6666667 70 16.6666667 23.3333333 43.3333333 10 43.3333333 70"
              ></polyline>
              <polyline
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="63.3333333 70 63.3333333 36.6666667 43.3333333 23.3333333"
              ></polyline>
              <line
                x1="30"
                y1="30"
                x2="30"
                y2="30.0333333"
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
              <line
                x1="30"
                y1="40"
                x2="30"
                y2="40.0333333"
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
              <line
                x1="30"
                y1="50"
                x2="30"
                y2="50.0333333"
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
              <line
                x1="30"
                y1="60"
                x2="30"
                y2="60.0333333"
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Enterprise;
