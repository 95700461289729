import React, { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  buttonColor?: "black" | "white" | "orange" | "orangeToWhite";
}

const Button = ({
  className,
  buttonColor,
  children,
  ...rest
}: IButtonProps) => {
  return (
    <button
      className={`${styles.button} ${buttonColor ? styles[buttonColor] : ""} ${
        className ? className : ""
      }`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
