import MatomoTracker from 'matomo-tracker';


const getAppSiteId = () => {
  const currentUrl = window.location.origin;
  const targetUrl = 'https://app.voicegain.ai';

  return currentUrl === targetUrl ? 6 : 5;
};

const matomoTracker = new MatomoTracker(getAppSiteId(), 'https://transcribe-dev.matomo.cloud/matomo.php');

matomoTracker.on('error', function(err) {
  console.log('Error tracking request: ', err);
});

export default matomoTracker;
