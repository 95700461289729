import { SERVER_URL, WEB_API_PATH } from "../../config";
import HttpService, {
  HttpMethod,
  ISearchParams,
} from "../../services/HttpService";
import { emailBody } from "./VoicegainApplicationApi.types";

const generateUrl = (resourcePath: string, searchParams?: ISearchParams) => {
  return HttpService.generateUrl(
    WEB_API_PATH,
    resourcePath,
    SERVER_URL,
    searchParams
  );
};

/*Send an email */
export const sendEmail = (emailBody: emailBody, token: string) => {
  const headers = { "Content-Type": "application/json" };
  const method = HttpMethod.Post;
  const url = generateUrl(`/email`);
  url.searchParams.append("token", token);
  url.searchParams.append("tokenType", "reCaptcha");
  const body = JSON.stringify(emailBody);
  const requestInfo: RequestInfo = url.toString();
  const requestInit: RequestInit = { headers, method, body };
  return fetch(requestInfo, requestInit);
};
