import { FC, HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLElement> {
  svgClass?: string | undefined;
}

const SentEmail: FC<Props> = ({ svgClass }) => (
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>05C8E2F5-8CDC-44D9-BDB9-86C51FB0941A</title>
    <g
      id="Enterprise"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="1-1-0-enterprise" transform="translate(-679.000000, -417.000000)">
        <g id="card" transform="translate(420.000000, 297.000000)">
          <g id="group" transform="translate(124.000000, 120.000000)">
            <g id="sent_email-icon" transform="translate(135.000000, 0.000000)">
              <polygon id="Path" points="0 0 80 0 80 80 0 80"></polygon>
              <line
                x1="33.3333333"
                y1="46.6666667"
                x2="70"
                y2="10"
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></line>
              <path
                d="M70,10 L48.3333333,70 C48.0346784,70.6517185 47.383557,71.0695707 46.6666667,71.0695707 C45.9497763,71.0695707 45.2986549,70.6517185 45,70 L33.3333333,46.6666667 L10,35 C9.3482815,34.7013451 8.93042928,34.0502237 8.93042928,33.3333333 C8.93042928,32.616443 9.3482815,31.9653216 10,31.6666667 L70,10"
                id="Path"
                stroke="#FEB33E"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SentEmail;
