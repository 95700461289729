/** @format */

import React, { useContext, useRef, useState } from "react";
import { DialogProps } from "@material-ui/core";
import Button from "../../components/Button/Button";
import styles from "./ContactSalesModal.module.scss";
import Input from "../../components/Input/Input";
import { Controller, useForm } from "react-hook-form";
import TextArea from "../../components/TextArea/TextArea";
import Select from "../../components/Select/Select";
import { emailBody } from "../../api/VoicegainApplicationApi/VoicegainApplicationApi.types";
import { sendEmail } from "../../api/VoicegainApplicationApi";
import Modal from "../../components/Modal/Modal";
import ReCaptcha from "../../components/ReCaptcha/ReCaptcha";
import { AppContext } from "../../App";
import SentEmail from "../../components/svg/SentEmail";
import { ModalType } from "./SignupSelector";
import Enterprise from "../../components/svg/Enterprise";
import { useTranslation } from "react-i18next";

const companySize = [
	{ label: "1-10", value: "1-10" },
	{ label: "11-50", value: "11-50" },
	{ label: "51-250", value: "51-250" },
	{ label: "250+", value: "250+" },
];

interface IContactSalesModalProps extends DialogProps {
	setOpen: (open: boolean) => void;
	modalType?: ModalType;
}

const ContactSalesModal = ({
	open,
	setOpen,
	modalType,
	...rest
}: IContactSalesModalProps) => {
	const { t } = useTranslation();
	const appContext = useContext(AppContext);
	const [emailSent, setEmailSent] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [token, setToken] = useState<string | undefined>(undefined);

	const reCaptchaRef = useRef(null);
	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors, isValid },
	} = useForm({ mode: "onChange" });

	const postEmail = async (emailBody: emailBody) => {
		try {
			if (token) {
				setIsLoading(true);
				let response = await sendEmail(emailBody, token);
				if (response.ok) {
					setEmailSent(true);
				} else
					throw new Error(
						t("Sorry, there was an error while trying to send the email.")
					);
			}
		} catch (err: any) {
			console.log(err);
			appContext.setSnackbarMessage(err.message);
			appContext.setSnackbarOpen(true);
		} finally {
			setIsLoading(false);
		}
	};

	const onSubmit = (data: any) => {
		const emailBody: emailBody = {
			to: ["Sales@voicegain.ai"],
			subject:
				modalType === ModalType.Waitlist
					? "Call Center Waitlist"
					: "Enterprise Inquiry",
			body: JSON.stringify(data),
		};
		postEmail(emailBody);
	};

	if (!emailSent) {
		return (
			<Modal isOpen={open} toggleOpen={setOpen} hideFooter spinning={isLoading}>
				<div className={styles.dialogWrap}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={styles.dialogBody}>
							<div className={styles.dialogBodyHeading}>
								<Enterprise />
								<h2>
									{modalType === ModalType.Waitlist
										? t("Join the waitlist!")
										: t("Interested in Voicegain Enterprise?")}
								</h2>
								<p>
									{t(
										"Please fill in your contact details and we will get in touch with you soon."
									)}
								</p>
							</div>
							<div className={styles.dialogBodyContent}>
								<div className={styles.inputContainer}>
									<label htmlFor="firstName">First Name</label>
									<Input
										type="text"
										placeholder={t("Enter your first name")}
										id="firstName"
										{...register("firstName", { required: true })}
									/>
									{errors.firstName && (
										<div className="form-error-container">
											Enter a first name
										</div>
									)}
								</div>
								<div className={styles.inputContainer}>
									<label htmlFor="lastName">Last Name</label>
									<Input
										type="text"
										placeholder={t("Enter your last name")}
										id="lastName"
										{...register("lastName", { required: true })}
									/>
									{errors.lastName && (
										<div className="form-error-container">
											Enter a last name
										</div>
									)}
								</div>
								<div className={styles.inputContainer}>
									<label htmlFor="businessEmail">Business Email</label>
									<Input
										type="email"
										placeholder={t("Enter your business email")}
										id="businessEmail"
										{...register("businessEmail", { required: true })}
									/>
									{errors.email && (
										<div className="form-error-container">
											Enter a business email
										</div>
									)}
								</div>
								<div className={styles.inputContainer}>
									<label htmlFor="title">Title</label>
									<Input
										type="text"
										placeholder={t("What is your job title?")}
										id="title"
										{...register("title")}
									/>
								</div>
								<div className={styles.inputContainer}>
									<label htmlFor="company">Company</label>
									<Input
										type="text"
										placeholder={t("Where do you work?")}
										id="company"
										{...register("company", { required: true })}
									/>
									{errors.company && (
										<div className="form-error-container">Enter a company</div>
									)}
								</div>
								<div className={styles.inputContainer}>
									<label htmlFor="company">Company Size</label>
									<Controller
										name="companySize"
										control={control}
										render={({ field: { onChange } }) => (
											<Select
												options={companySize}
												onSelectOption={(e) => {
													if (e) onChange(e.value);
												}}
											/>
										)}
									/>
								</div>
								<div className={styles.inputContainer} id={styles.comments}>
									<label htmlFor="comments">Comment</label>
									<TextArea
										rows={4}
										placeholder={t("Add your comment (max. 300 characters)")}
										id="comments"
										maxLength={300}
										{...register("comments")}
									/>
								</div>
							</div>
						</div>
						<div className={styles.dialogFooter}>
							<div className={styles.reCaptchaContainer}>
								<ReCaptcha
									onSuccess={(token) => setToken(token)}
									onExpire={() => setToken(undefined)}
									reCaptchaRef={reCaptchaRef}
								/>
							</div>
							<div className={styles.actionWrap}>
								<Button
									type="button"
									buttonColor="white"
									onClick={() => setOpen(false)}>
									Cancel
								</Button>
								<Button
									type="submit"
									buttonColor="black"
									className="btn btn-primary btn-sm"
									disabled={!isValid || !token}>
									Send
								</Button>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		);
	} else
		return (
			<Modal isOpen={open} toggleOpen={setOpen} hideFooter>
				<div className={styles.sentEmailBody}>
					<SentEmail />
					<h2>Thank you! Request received.</h2>
					<p>We will be in touch with you soon.</p>
				</div>
			</Modal>
		);
};

export default ContactSalesModal;
