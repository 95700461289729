/** @format */

import React from "react";
import styles from "./AuthSidebar.module.scss";
import { toAbsoluteUrl } from "../../utils";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import { LegalTextsLinks } from "../../constants/AppLinks";

const AuthSidebar = () => {
	const { t } = useTranslation();

	return (
		<div
			className={styles.authSidebar}
			style={{ backgroundImage: `url(${"/images/login-left-bg.png"})` }}>
			<figure className={styles.sidebarLogo}>
				<img
					src={toAbsoluteUrl("/images/voicegainLogo.png")}
					alt="voicegainLogo"
				/>
				<b>{t("Speech-to-Text that works")}</b>
			</figure>
			<div className={styles.sidebarContentWrapper}>
				<div className={styles.sidebarContent}>
					<h2>{t("Voice AI for Business")}</h2>
					<h6>
						{t(
							"Use APIs to embed transcription into your App. Use Transcribe App as an AI meeting assistant or to transcribe audio content."
						)}
					</h6>
				</div>
			</div>
			<div className={styles.appSelectorBtnArea}>
				<a
					className={styles.linkButton}
					href={LegalTextsLinks.termsAndConditions}
					target="_blank"
					rel="noopener noreferrer"
				>
					{t("Terms and Conditions")}
				</a>
				<a
					className={styles.linkButton}
					href={LegalTextsLinks.privacyPolicy}
					target="_blank"
					rel="noopener noreferrer"
				>
					{t("Privacy Policy")}
				</a>
			</div>

			
		</div>
	);
};

export default AuthSidebar;
