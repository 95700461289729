import React from "react";
import styles from "./Modal.module.scss";
import { Dialog } from "@material-ui/core";
import ModalBody from "./ModalBody";
import Button from "../Button/Button";

export interface IModalProps {
  title?: string;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  onOk?: (s?: any) => void;
  footer?: React.ReactNode;
  hideFooter?: boolean;
  spinning?: boolean;
  children?: React.ReactNode;
  saveDisabled?: boolean;
}

const Modal = ({
  title,
  isOpen,
  toggleOpen,
  onOk,
  children,
  footer,
  spinning,
  hideFooter,
  saveDisabled,
}: IModalProps) => {
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 10 },
      }}
      scroll="body"
      maxWidth="lg"
    >
      <div className={styles.contentContainer}>
        <ModalBody title={title} toggleOpen={toggleOpen} spinning={spinning}>
          {children}
        </ModalBody>
        {footer ? (
          footer
        ) : hideFooter ? null : (
          <div className={styles.footerContainer}>
            <Button
              onClick={() => toggleOpen(false)}
              type="button"
              buttonColor="white"
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={onOk}
              disabled={saveDisabled}
              buttonColor="black"
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Modal;
