import React from "react";
import styles from "./Loading.module.scss";
import { HTMLAttributes } from "react";
import LoadingSpinner from "../svg/LoadingSpinner";

/*Used for a spinner to overlay a page*/

interface ILoadingOverlayProps extends HTMLAttributes<HTMLElement> {
  spinning?: boolean;
  children: React.ReactNode;
}

const LoadingOverlay = ({
  spinning,
  children,
  ...rest
}: ILoadingOverlayProps) => {
  if (spinning)
    return (
      <div className={styles.overlayContainer} {...rest}>
        <div className={styles.spinnerContainer}>
          <LoadingSpinner height={75} width={75} />
        </div>
        {children}
      </div>
    );
  else return <>{children}</>;
};

export default LoadingOverlay;
