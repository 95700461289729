/** @format */

import React from "react";
import styled from "styled-components";
import { JsxElement } from "typescript";
import styles from "./Input.module.scss";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	error?: string;
	unit?: string | React.ReactNode;
	errorColor?: string;
	errorFontSize?: string;
	errorFontWeight?: number;
	inputBackgroundColor?: string;
	inputBorderColor?: string;
	icon?: React.ReactNode;
}

export const ErrorLabel = styled.h5<{
	color?: string;
	fontSize?: string;
	fontWeight?: number;
}>`
	color: ${(props) => (props.color ? props.color : "#e35555")};
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
	margin-top: 4px;
	margin-bottom: 16px;
`;

const CustomInput = styled.input<{
	backgroundColor?: string;
	borderColor?: string;
	hasUnit?: boolean;
}>`
  font-size: 16px;
  width: 100%;
  height: 40px;
  padding-left: 16px;
  padding-right: ${(props) => (props.hasUnit ? "32px" : "0")};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) =>
		props.borderColor
			? `1px solid ${props.borderColor};`
			: "1px solid #e3e3e3;"}
  border-radius: 4px;
  &:focus {
    outline-color: ${(props) => props.borderColor}
  }
  &::placeholder {
    color: #e3e3e3;
  }
  &:disabled {
    background: #F2F2F2;
  }
`;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			label,
			unit,
			error,
			errorColor,
			errorFontSize,
			errorFontWeight,
			inputBackgroundColor,
			inputBorderColor,
			className,
			icon,
			...rest
		}: InputProps,
		ref
	) => {
		return (
			<div
				className={
					className
						? `${styles.inputContainer} ${className}`
						: styles.inputContainer
				}>
				{label ? <label>{label}</label> : ""}
				<div className={styles.inputInnerContainer}>
					<CustomInput
						type="text"
						ref={ref}
						backgroundColor={inputBackgroundColor}
						borderColor={inputBorderColor}
						hasUnit={unit ? true : false}
						{...rest}
					/>
					{unit ? <span className={styles.unitAbsolute}>{unit}</span> : ""}
				</div>
				{error ? (
					<ErrorLabel
						color={errorColor}
						fontWeight={errorFontWeight}
						fontSize={errorFontSize}>
						{error}
					</ErrorLabel>
				) : (
					""
				)}
			</div>
		);
	}
);

export default Input;
