/** @format */

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import App from "./App";
import ReactGA from "react-ga4";
import "./index.scss";
import { DOMAIN_URL } from "./config";

/*Initialize Google Analytics 4 only on prod (voicegain)*/
if (DOMAIN_URL.includes("voicegain")) {
	ReactGA.initialize("G-TEPLL7KQ6L");
	ReactGA.send("pageview");
	console.log("G4 Running.");
}

ReactDOM.render(
	<React.StrictMode>
		<React.Suspense fallback="Loading...">
			<App />
		</React.Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
