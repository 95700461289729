/** @format */

import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import AuthSidebar from "../../components/Sidebar/AuthSidebar";
import AuthTopRow from "../../components/Sidebar/AuthTopRow";
import Alpha from "../../components/svg/Alpha";
import CallCenterIcon from "../../components/svg/CallCenter";
import DeveloperIcon from "../../components/svg/Developer";
import TranscriptionIcon from "../../components/svg/Transcription";
import { SignupLinks } from "../../constants/AppLinks";
import { AppSelectionValues } from "../AppSelector/AppSelector";
import ContactSalesModal from "./ContactSalesModal";
import styles from "./SignupSelector.module.scss";
import matomoTracker from "../../matomo";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

export enum ModalType {
	Waitlist = "waitlist",
	Enterprise = "enterprise",
}

const SignupSelector = () => {
	const { t } = useTranslation();
	const { Transcription, Developer, CallCenter } = AppSelectionValues;

	const [selectedApp, setSelectedApp] = useState<
		AppSelectionValues | undefined
	>(undefined);
	const [modalType, setModalType] = useState<ModalType>(ModalType.Enterprise);
	const [contactSalesModalVisible, setContactSalesModalVisible] =
		useState<boolean>(false);
	const [transcriptionTooltipHovered, setTranscriptionTooltipHovered] =
		useState<boolean>(false);
	const [developerTooltipHovered, setDeveloperTooltipHovered] =
		useState<boolean>(false);

	const trackButtonClick = (
		category: string,
		action: string,
		event: string
	) => {
		matomoTracker.track({
			url: window.location.href,
			e_c: category,
			e_a: action,
			e_n: event,
		});
	};

	useEffect(() => {
		matomoTracker.track({
			url: window.location.href,
			action_name: window.location.href,
		});
	}, []);

	return (
		<div className={styles.signUpWrap}>
			<AuthSidebar />
			<div className={styles.signupContent}>
				<AuthTopRow />
				<div className={styles.signupPlansWrap}>
					<div className={styles.signupPlans}>
						<div className={styles.signupTitle}>
							<h2>{t("What kind of a user are you?")}</h2>
							<p>
								{t(
									"Select from one of the following applications based on your use-case"
								)}
							</p>
						</div>
						<div className={styles.signupColWrap}>
							<div className={styles.signupColOuter}>
								<button className={styles.appSelectorCol} type="button">
									<div>
										<div className={styles.signupColHead}>
											<i className={styles.signupUserIcn}>
												<TranscriptionIcon />
											</i>
											<h6 className={styles.logInHeading}>
												{t("Transcription")}
											</h6>
											<div className={styles.noCard}>
												{t("Free plan forever")}{" "}
												<span
													className={styles.superText}
													onMouseEnter={() =>
														setTranscriptionTooltipHovered(true)
													}
													onMouseLeave={() =>
														setTranscriptionTooltipHovered(false)
													}>
													¹
													{transcriptionTooltipHovered ? (
														<div
															style={{
																position: "absolute",
																right: "-160px",
																top: "-75px",
																backgroundColor: "#fff",
																border: "1px solid #ccc",
																width: "150px",
																height: "75px",
																display: "flex",
																justifyContent: "center",
																textAlign: "center",
																alignItems: "center",
																borderRadius: "5px",
																fontSize: "12px",
															}}>
															{t(
																"More transcription minutes available in paid plans"
															)}
														</div>
													) : null}
												</span>
											</div>
										</div>
										<ul className={styles.signupPlanListing}>
											<li>{t("Dictate using microphone")}</li>
											<li>{t("Upload and transcribe any audio file")}</li>
											<li>{t("Automatically upload Zoom Recordings")}</li>
											<li>{t("Generate Meeting Minutes (chatGPT)")}</li>
											<li>{t("Share transcripts")}</li>
											<li>{t("Capture and view Zoom Video")}</li>
											<li>{t("Capture and view Zoom Chat")}</li>
											<li>{t("Advanced search")}</li>
											<div className={styles.comingSoon}>
												{t("Coming Soon")}
											</div>
											<li>{t("Integration with MS Teams etc.")}</li>
										</ul>

										<div className={styles.signupBtnArea}>
											<Button
												className={styles.loginBtn}
												type="button"
												buttonColor="orange"
												onClick={() => {
													trackButtonClick("Signup", "continue", "transcribe");
													window.location.href = SignupLinks[Transcription];
												}}>
												{t("Continue")} →
											</Button>
										</div>
									</div>
								</button>
							</div>
							<div className={styles.signupColOuter}>
								<button className={styles.appSelectorCol} type="button">
									<div>
										<div className={styles.signupColHead}>
											<i className={styles.signupUserIcn}>
												<DeveloperIcon />
											</i>
											<h6 className={styles.logInHeading}>{t("Developer")}</h6>
											<div className={styles.noCard}>
												$50 {t("Credit")}{" "}
												<span
													className={styles.superText}
													onMouseEnter={() => setDeveloperTooltipHovered(true)}
													onMouseLeave={() =>
														setDeveloperTooltipHovered(false)
													}>
													²
													{developerTooltipHovered ? (
														<div
															style={{
																position: "absolute",
																right: "-190px",
																top: "-90px",
																backgroundColor: "#fff",
																border: "1px solid #ccc",
																width: "180px",
																height: "90px",
																display: "flex",
																justifyContent: "center",
																textAlign: "center",
																alignItems: "center",
																borderRadius: "5px",
																fontSize: "12px",
															}}>
															{t(
																"No credit card required to start; After $50 credit, buy usage ($25 minimum)"
															)}
														</div>
													) : null}
												</span>
											</div>
										</div>
										<ul className={styles.signupPlanListing}>
											<li>
												{t("Speech-to-Text Web APIs (Offline, Streaming)")}
											</li>
											<li>{t("Speech Analytics APIs (Offline, Real-time)")}</li>
											<li>
												{t("Telephony Bot APIs (Voicegain as SIP Endpoint)")}
											</li>
											<li>{t("Model Training APIs")}</li>
											<li>{t("MRCP ASR/STT (Upon request)")}</li>
											<li>{t("Edge Deployment (Upon request)")}</li>
											<br />
											<li className={styles.superTextContent}>
												<span className={styles.boldNumeral}>2</span>{" "}
												{t(
													"No credit card required to start; After $50 credit, buy usage ($25 minimum)"
												)}
											</li>
										</ul>

										<div className={styles.signupBtnArea}>
											<Button
												className={styles.loginBtn}
												type="button"
												buttonColor="orange"
												onClick={() => {
													trackButtonClick("Signup", "continue", "developer");
													window.location.href = SignupLinks[Developer];
												}}>
												{t("Continue as Developer")} →
											</Button>
										</div>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<ContactSalesModal
					open={contactSalesModalVisible}
					setOpen={(open) => setContactSalesModalVisible(open)}
					modalType={modalType}
				/>
			</div>
			<LanguageSelector />
		</div>
	);
};

export default SignupSelector;
